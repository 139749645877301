const MENU_CONTENT = [
  {
    title: 'menu.about_us',
    url: '/',
    sub_content: [
      {
        title: 'menu.about',
        url: '/about-us'
      },
      // {
      //   title: 'menu.team',
      //   url: '/team'
      // },
      {
        title: 'menu.career',
        url: '/career'
      },
      {
        title: 'menu.partners',
        url: '/partners'
      },
      {
        title: 'menu.contact',
        url: '/contact'
      }
    ]
  },
  {
    title: 'menu.mentoring_program',
    url: '/mentoring-program',
    sub_content: [
      {
        title: 'menu.medbiz',
        url: '/mentoring-program'
      },
      {
        title: 'menu.fa',
        url: '/future-academy'
      },
      // {
      //   title: 'menu.green',
      //   url: '/green-innovations-challenge'
      // },
      {
        title: 'menu.mpk',
        url: '/mpk'
      },
      {
        title: 'menu.ens',
        url: '/ens'
      },
      {
        title: 'menu.fsts',
        url: '/fsts'
      }
    ]
  },
  {
    title: 'menu.news',
    url: '/news/',
    sub_content: [
      {
        title: 'menu.news',
        url: '/news/'
      },
      {
        title: 'menu.articles',
        url: '/news/articles'
      },
      {
        title: 'menu.interviews',
        url: '/news/interviews'
      },
      {
        title: 'menu.workshops',
        url: '/news/workshops'
      }
    ]
  },
  {
    title: 'menu.incubator',
    url: '/incubator',
    sub_content: [
      {
        title: 'menu.about_incubator',
        url: '/incubator#about'
      }
    ]
  }
];

export default MENU_CONTENT;
