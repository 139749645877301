import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import '../style/footer.css'
import MENU_CONTENT from '../../menu/components/menu_content';
import { withPrefix } from 'gatsby';

const Footer = ({darkTheme}) => {
  const {t} = useTranslation();

  const bgColor = darkTheme ? {backgroundColor: '#080E14'} : {backgroundColor: 'white'}

  const themeStyle = darkTheme ? 'dark-theme' : 'white-theme'

  return (
    <div className={`footer-ctn ${themeStyle}`} style={bgColor}>
      <footer className="container">
        <div className="footer-menu-ctn">
          {
            MENU_CONTENT.map(item => (
              <div key={item.title}>
                <h4>
                  <Link to={item.url}>
                    {t(item.title)}
                  </Link>
                </h4>
                {

                  item.sub_content.map(sub => (
                    <p key={sub.title} className="footer-submenu-text">
                      <Link to={sub.url}>
                        {t(sub.title)}
                      </Link>
                    </p>
                  ))
                }
              </div>
            ))
          }

          <div>
            <h4>
              {t`footer.whats-up`}
            </h4>
            <div className="social-ctn-footer">
              <a href="https://www.facebook.com/InnovationsHubFoundation/" target="_blank" rel="noreferrer">
                <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8125 4.59375 22.6406 10.1719 23.4844V15.375H7.21875V12H10.1719V9.46875C10.1719 6.5625 11.9062 4.92188 14.5312 4.92188C15.8438 4.92188 17.1562 5.15625 17.1562 5.15625V8.01562H15.7031C14.25 8.01562 13.7812 8.90625 13.7812 9.84375V12H17.0156L16.5 15.375H13.7812V23.4844C19.3594 22.6406 23.625 17.8125 23.625 12Z"/>
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/innovations-hub/" target="_blank" rel="noreferrer">
                <svg width="25" height="25" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 0.5H1.45312C0.65625 0.5 0 1.20312 0 2.04688V20C0 20.8438 0.65625 21.5 1.45312 21.5H19.5C20.2969 21.5 21 20.8438 21 20V2.04688C21 1.20312 20.2969 0.5 19.5 0.5ZM6.32812 18.5H3.23438V8.51562H6.32812V18.5ZM4.78125 7.10938C3.75 7.10938 2.95312 6.3125 2.95312 5.32812C2.95312 4.34375 3.75 3.5 4.78125 3.5C5.76562 3.5 6.5625 4.34375 6.5625 5.32812C6.5625 6.3125 5.76562 7.10938 4.78125 7.10938ZM18 18.5H14.8594V13.625C14.8594 12.5 14.8594 11 13.2656 11C11.625 11 11.3906 12.2656 11.3906 13.5781V18.5H8.29688V8.51562H11.25V9.875H11.2969C11.7188 9.07812 12.75 8.23438 14.25 8.23438C17.3906 8.23438 18 10.3438 18 13.0156V18.5Z"/>
                </svg>
              </a>
              <a href="https://www.youtube.com/channel/UCtG_eO9jFkM5Joq0NMrAwCg" target="_blank" rel="noreferrer">
                <svg width="28" height="25" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.615.184c-3.604-.246-11.631-.245-15.23 0C.488.45.029 2.804 0 9c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 17.55 23.971 15.196 24 9c-.029-6.185-.484-8.549-4.385-8.816zM9 13V5l8 3.993L9 13z"/>
                </svg>
              </a>
            </div>

            <h4 style={{marginTop: '2rem'}}>
              {t`menu.write-to-us`}!
            </h4>

            <p className="all-lowercase">
              hello@innovationshub.pl
            </p>
          </div>

        </div>

      </footer>
      <div className={themeStyle}>


      <div className="copyright-ctn">
        <div className="container footer-xd">
          <div className="footer-end">
            <p>
              <Link to="/rodo/">
                {t`footer.policy`}
              </Link>
              <span className="bullet">•</span>
            </p>
            <p>
              <Link to="/regulations/mentoring-program">
                {t`footer.regulations`}
              </Link>
              <span className="bullet">•</span>
            </p>
            <p>
              <a href={withPrefix('Działaność Fundacji 2021-2023.pdf')} target="_blank" rel="noreferrer" className="read-more-btn full-bg">
                {t`footer.raport2021_2023`}
              </a>
              <span className="bullet">•</span>
            </p>
            <p>
              <Link to="/statute/">
                {t`footer.statute`}
              </Link>
            </p>
          </div>
        </div>
        <div className="container">
          <p style={{textAlign: 'right', whiteSpace: 'unset'}}>
            Copyright {new Date().getFullYear()} Innovations Hub Foundation
          </p>
        </div>

      </div>

      </div>
    </div>
  );
}

export default Footer;
